import CrudController from "./crud_controller";

export default class extends CrudController {
  connect() {
    this.siteNum = this.data.get("siteNum");
    this.loadList();
  }

  toggle(event) {
    let dispatchType = event.currentTarget.dataset.dispatchType;
    let selectedFlag = event.currentTarget.checked;
    let controller = this;
    App.$rails.ajax({
      type: "PATCH",
      url: `/sites/${this.siteNum}/dispatch-types/${dispatchType}`,
      data: new URLSearchParams({ selected_flag: selectedFlag }).toString(),
      success(data) {
        controller.loadList();
      },
      error(data) {
        controller.loadList();
      }
    });
  }

  loadList() {
    fetch(`/sites/${this.siteNum}/dispatch-types`)
      .then(response => this.handleFetchErrors(response, this.listTarget))
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
      });
  }
}