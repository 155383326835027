import CrudPaginationController from "./crud_pagination_controller";

export default class extends CrudPaginationController {
  connect() {
    this.siteNum = this.data.get("siteNum");
    this.loadList();
  }

  loadList(params) {
    fetch(`/sites/${this.siteNum}/history/list?${this.paramsString()}&${params}`)
      .then(response => this.handleFetchErrors(response, this.listTarget))
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
        this.hideLoading();
      });
  }
}