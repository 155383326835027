import CrudController from "./crud_controller";

export default class extends CrudController {
  pageUp(event) {
    event.preventDefault();
    this.showLoading();
    this.loadList("function_name=PageUp");
  }

  pageDown(event) {
    event.preventDefault();
    this.showLoading();
    this.loadList("function_name=PageDown");
  }

  paramsString() {
    return new URLSearchParams(window.location.search).toString();
  }
}