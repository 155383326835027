import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "link", "form" ];

  showForm(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.formTarget.classList.contains("hidden")) {
      this.formTarget.classList.remove("hidden");
      this.hideLink();
    }
  }

  hideForm(event) {
    event.preventDefault();
    if (!this.formTarget.classList.contains("hidden")) {
      this.formTarget.classList.add("hidden");
      this.showLink();
    }
  }

  showLink() {
    if (this.linkTarget.classList.contains("hidden")) {
      this.linkTarget.classList.remove("hidden");
    }
  }

  hideLink() {
    if (!this.linkTarget.classList.contains("hidden")) {
      this.linkTarget.classList.add("hidden");
    }
  }
}