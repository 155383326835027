import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "editor", "xmlBody" ];

  connect() {
    this.renderEditor();
  }

  renderEditor() {
    const toolbarOptions = [
      ["bold", "italic"],
      [{ "color": [
          // Colors: Black, red, orange, yellow, green, teal/blue-green, blue, purple
          "#000000", "#e53e3e", "#d69e2e", "#ffba00", "#38a169", "#319795", "#3182ce", "#805ad5",
          // Light gray and light colors
          "#cbd5e0", "#fc8181", "#f6ad55", "#f6e05e", "#68d391", "#4fd1c5", "#63b3ed", "#b794f4",
          // Dark gray and dark colors
          "#2d3748", "#9b2c2c", "#9c4221", "#975a16", "#276749", "#285e61", "#2c5282", "#553c9a"
        ]
      }]
    ];
    let editor = new App.$quill("#quill_editor", {
      theme: "snow",
      modules: {
        toolbar: toolbarOptions
      }
    });
    editor.root.innerHTML = this.editorTarget.textContent;

    // Hook Quill into form
    let xmlForm = document.getElementById("xml_form")
    if (xmlForm) {
      let htmlBodyInput = document.getElementById("html_body");
      xmlForm.onsubmit = function() {
        htmlBodyInput.value = editor.root.innerHTML;
      }
    }
  }
}