import ChartController from "./chart_controller";

export default class extends ChartController {
  static targets = [ "canvas" ];

  connect() {
    super.connect();
    this.renderChart();
  }

  renderChart() {
    new App.$chartjs(this.canvasTarget, {
      type: "horizontalBar",
      data: {
        labels: this.labels,
        datasets: [
          {
            label: "Actions",
            data: this.counts,
            backgroundColor: App.arrayOfRGBColors(this.labels.length, 0.75),
            borderColor: App.arrayOfRGBColors(this.labels.length),
            borderWidth: 1,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            ticks: {
              min: 0,
            }
          }]
        }
      },
    });
  }
}