import ListLoadController from "./list_load_controller";

export default class extends ListLoadController {
  initialize() {
    this.startUpdating();
  }

  disconnect() {
    this.stopUpdating();
  }

  startUpdating() {
    this.loadList();
    this.updateInterval = setInterval(() => {
      this.loadList();
    }, 10 * 1000);
  }

  stopUpdating() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  }

  loadList() {
    fetch(`/alarms/list?${this.paramsString()}`)
      .then(response => this.handleFetchErrors(response, this.listTarget))
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
        App.$iconify.scanDOM();
      })
      .finally(() => {
        this.hideLoading();
      });
  }

  paramsString() {
    return new URLSearchParams(window.location.search).toString();
  }
}