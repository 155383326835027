import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "bar" ];

  initialize() {
    super.initialize();
    this.logoutMinutes = parseInt(this.data.get("logoutMinutes"));
    this.warningMinutes = Math.max(this.logoutMinutes - 5, 0);
    this.logoutUrl = this.data.get("logoutUrl");
  }

  connect() {
    this.startIdleLogoutTimer();
  }

  disconnect() {
    this.stopIdleLogoutTimer();
  }

  showBar() {
    if (this.barTarget.classList.contains("hidden")) {
      this.barTarget.classList.remove("hidden");
    }
  }

  hideBar() {
    if (!this.barTarget.classList.contains("hidden")) {
      this.barTarget.classList.add("hidden");
    }
  }

  startIdleLogoutTimer() {
    this.idleWarningTimeout = setTimeout(() => {
      this.showBar();
    },1000 * 60 * this.warningMinutes);
    this.idleLogoutTimeout = setTimeout(() => {
      window.location.replace(`${this.logoutUrl}?idle_logout=1`);
    }, 1000 * 60 * this.logoutMinutes);
  }

  stopIdleLogoutTimer() {
    if (this.idleWarningTimeout) {
      clearTimeout(this.idleWarningTimeout);
    }
    if (this.idleLogoutTimeout) {
      clearTimeout(this.idleLogoutTimeout);
    }
  }

  resetIdleLogoutTimer() {
    if (this.idleWarningTimeout) {
      clearTimeout(this.idleWarningTimeout);
    }
    if (this.idleLogoutTimeout) {
      clearTimeout(this.idleLogoutTimeout);
    }
    this.hideBar();
    this.startIdleLogoutTimer();
  }
}
