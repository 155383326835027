import ListLoadController from "./list_load_controller";

export default class extends ListLoadController {
  static targets = ["message", "newLink"];

  hideNewLink() {
    if (!this.newLinkTarget.classList.contains("hidden")) {
      this.newLinkTarget.classList.add("hidden");
    }
  }

  showNewLink() {
    if (this.newLinkTarget.classList.contains("hidden")) {
      this.newLinkTarget.classList.remove("hidden");
    }
  }

  handleResponse(event) {
    if (event.detail[0].success) {
      this.loadList();
    } else {
      this.messageTarget.innerText = event.detail[0].error;
    }
  }

  cancelForm(event) {
    event.preventDefault();
    this.clearMessage();
    this.loadList();
  }

  clearMessage() {
    this.messageTarget.innerText = "";
  }
}