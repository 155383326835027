import DropdownIconController from "./dropdown_icon_controller";

export default class extends DropdownIconController {
  static targets = [ "badge" ];

  initialize() {
    super.initialize();
  }

  connect() {
    super.connect();
    this.updateBadge();
    this.startKeepingBadgeUpdated();
  }

  disconnect() {
    super.disconnect();
    this.stopKeepingBadgeUpdated();
  }

  handleClick(event) {
    if (this.iconTarget.contains(event.target)
      && this.dropdownContentTarget.classList.contains("hidden")) {
      this.loadNotifications();
    }
    super.handleClick(event);
  }

  loadNotifications() {
    fetch(`/notifications/current?platform=web`)
      .then(response => this.handleFetchErrors(response, this.dropdownContentTarget))
      .then(response => response.text())
      .then(html => {
        this.dropdownContentTarget.innerHTML = html;
        App.$iconify.scanDOM();
      });
  }

  updateBadge() {
    fetch(`/notifications/badge?platform=web`)
      .then(response => response.text())
      .then(html => {
        this.badgeTarget.innerHTML = html;
      });
  }

  startKeepingBadgeUpdated() {
    let controller = this;
    this.updateBadgeInterval = setInterval(function() {
      controller.updateBadge();
    }, 20 * 1000);
  }

  stopKeepingBadgeUpdated() {
    if (this.updateBadgeInterval) {
      clearInterval(this.updateBadgeInterval);
    }
  }
}