import ChartController from "./chart_controller";

export default class extends ChartController {
  connect() {
    super.connect();
    this.renderChart();
  }

  renderChart() {
    new App.$chartjs(this.canvasTarget, {
      type: "line",
      data: {
        labels: this.labels,
        datasets: [
          {
            label: "Alarm Factor",
            data: this.counts,
            backgroundColor: (parseFloat(this.counts[0]) > parseFloat(this.counts[this.counts.length - 1])) ? "rgba(72,187,120,0.75)" : "rgba(245,101,101,0.75)",
            borderColor: (parseFloat(this.counts[0]) > parseFloat(this.counts[this.counts.length - 1])) ? "rgb(72,187,120)" : "rgb(245,101,101)",
            borderWidth: 1,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: "Days Ago"
            },
          }]
        }
      },
    });
  }
}