import CrudController from "./crud_controller";

export default class extends CrudController {
  connect() {
    this.siteNum = this.data.get("siteNum");
    this.loadList();
  }

  loadList() {
    this.clearMessage();
    fetch(`/sites/${this.siteNum}/code-words`)
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
        this.showNewLink();
      });
  }

  new(event) {
    this.clearMessage();
    fetch(`/sites/${this.siteNum}/code-words/new`)
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
        App.applyFlatpickr();
        this.hideNewLink();
      });
  }

  edit(event) {
    this.clearMessage();
    let seqNum = event.currentTarget.dataset.seqNum;
    fetch(`/sites/${this.siteNum}/code-words/${seqNum}/edit`)
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
        App.applyFlatpickr();
        this.hideNewLink();
      });
  }
}