import DropdownIconController from "./dropdown_icon_controller";

export default class extends DropdownIconController {

  initialize() {
    super.initialize();
  }

  connect() {
    super.connect();
    this.startKeepingAlive();
  }

  disconnect() {
    super.disconnect();
    this.stopKeepingAlive();
  }

  startKeepingAlive() {
    this.keepAliveInterval = setInterval(() => {
      App.$rails.ajax({
        type: "POST",
        url: `/keep-alive`,
        data: new URLSearchParams({ after_login_url: window.location.href }).toString()
      });
    }, 5 * 60 * 1000);
  }

  stopKeepingAlive() {
    if (this.keepAliveInterval) {
      clearInterval(this.keepAliveInterval);
    }
  }
}
