import CrudController from "./crud_controller";

export default class extends CrudController {
  connect() {
    this.siteNum = this.data.get("siteNum");
    this.contactNum = this.data.get("contactNum");
    this.loadList();
  }

  loadList() {
    this.clearMessage();
    fetch(`/sites/${this.siteNum}/contacts/${this.contactNum}/lists`)
      .then(response => this.handleFetchErrors(response, this.listTarget))
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
        this.showNewLink();
      });
  }

  new(event) {
    this.clearMessage();
    fetch(`/sites/${this.siteNum}/contacts/${this.contactNum}/lists/new`)
      .then(response => this.handleFetchErrors(response, this.listTarget))
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
        this.hideNewLink();
      });
  }

  edit(event) {
    this.clearMessage();
    let seqNum = event.currentTarget.dataset.seqNum;
    let type = event.currentTarget.dataset.type;
    fetch(`/sites/${this.siteNum}/contacts/${this.contactNum}/lists/${seqNum}/${type}/edit`)
      .then(response => this.handleFetchErrors(response, this.listTarget))
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
        this.hideNewLink();
      });
  }
}