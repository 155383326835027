import CrudController from "./crud_controller";

export default class extends CrudController {
  connect() {
    this.siteNum = this.data.get("siteNum");
    this.devNum = this.data.get("devNum");
    this.loadList();
  }

  loadList() {
    fetch(`/sites/${this.siteNum}/devices/${this.devNum}/users`)
      .then(response => this.handleFetchErrors(response, this.listTarget))
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
      });
  }
}