import BaseController from "./base_controller";

export default class extends BaseController {
  static targets = ["list", "loading"];

  loadList() {
    // Child controllers should define this method.
  }

  showLoading() {
    if (this.loadingTarget.classList.contains("hidden")) {
      this.loadingTarget.classList.remove("hidden");
      this.hideList();
    }
  }

  hideLoading() {
    if (!this.loadingTarget.classList.contains("hidden")) {
      this.loadingTarget.classList.add("hidden");
      this.showList();
    }
  }

  showList() {
    if (this.listTarget.classList.contains("hidden")) {
      this.listTarget.classList.remove("hidden");
    }
  }

  hideList() {
    if (!this.listTarget.classList.contains("hidden")) {
      this.listTarget.classList.add("hidden");
    }
  }
}