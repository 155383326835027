import BaseController from "./base_controller";

export default class extends BaseController {
  static targets = [ "list", "transactionList", "detailList", "dateRow", "transactionRow" ];

  connect() {
    this.siteNum = this.data.get("siteNum");
    this.loadList();
  }

  loadList(params) {
    fetch(`/sites/${this.siteNum}/changes/list`)
      .then(response => this.handleFetchErrors(response, this.listTarget))
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
      });
  }

  loadTransactionList(event) {
    let date = event.currentTarget.dataset.date;
    this.highlightRow(this.dateRowTargets, this.dateRowTargets.indexOf(event.currentTarget));
    fetch(`/sites/${this.siteNum}/changes/date/${date}/list`)
      .then(response => this.handleFetchErrors(response, this.transactionListTarget))
      .then(response => response.text())
      .then(html => {
        this.transactionListTarget.innerHTML = html;
      });
  }

  loadDetailList(event) {
    let serverNum = event.currentTarget.dataset.serverNum;
    let seqNumBig = event.currentTarget.dataset.seqNumBig;
    this.highlightRow(this.transactionRowTargets, this.transactionRowTargets.indexOf(event.currentTarget));
    fetch(`/sites/${this.siteNum}/changes/detail/${serverNum}/${seqNumBig}/list`)
      .then(response => this.handleFetchErrors(response, this.detailListTarget))
      .then(response => response.text())
      .then(html => {
        this.detailListTarget.innerHTML = html;
      });
  }

  highlightRow(targets, index) {
    this.removeAllRowHighlights(targets);
    let rowTarget = targets[index];
    if (!rowTarget.classList.contains("bg-indigo-100")) {
      rowTarget.classList.add("bg-indigo-100");
    }
  }

  removeAllRowHighlights(targets) {
    for (const rowTarget of targets) {
      if (rowTarget.classList.contains("bg-indigo-100")) {
        rowTarget.classList.remove("bg-indigo-100");
      }
    }
  }
}
