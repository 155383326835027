import ListLoadController from "./list_load_controller";

export default class extends ListLoadController {
  static targets = ["playingButton", "pausedButton"];

  initialize() {
    this.startUpdating();
  }

  disconnect() {
    this.stopUpdating();
  }

  startUpdating(event) {
    this.loadList();
    this.updateInterval = setInterval(() => {
      this.loadList();
    }, 10 * 1000);
    this.showPlayingButton();
  }

  stopUpdating(event) {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
    this.showPausedButton();
  }

  showPlayingButton() {
    if (this.playingButtonTarget.classList.contains("hidden")) {
      this.pausedButtonTarget.classList.add("hidden");
      this.playingButtonTarget.classList.remove("hidden");
    }
  }

  showPausedButton() {
    if (this.pausedButtonTarget.classList.contains("hidden")) {
      this.playingButtonTarget.classList.add("hidden");
      this.pausedButtonTarget.classList.remove("hidden");
    }
  }

  loadList() {
    fetch(`/signal-scroll/list`)
      .then(response => this.handleFetchErrors(response, this.listTarget))
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
        App.$iconify.scanDOM();
      })
      .finally(() => {
        this.hideLoading();
      });
  }
}