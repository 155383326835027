import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "expander", "moreIcon", "lessIcon" ];

  toggleExpander(event) {
    event.preventDefault();
    if (this.expanderTarget.classList.contains("hidden")) {
      this.expanderTarget.classList.remove("hidden");
    } else {
      this.expanderTarget.classList.add("hidden");
    }

    if (this.lessIconTarget.classList.contains("hidden")) {
      this.lessIconTarget.classList.remove("hidden");
      this.moreIconTarget.classList.add("hidden");
    } else {
      this.lessIconTarget.classList.add("hidden");
      this.moreIconTarget.classList.remove("hidden");
    }
  }
}