import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "urlField", "contentField" ];

  showURLField(event) {
    if (this.urlFieldTarget.classList.contains("hidden")) {
      this.urlFieldTarget.classList.remove("hidden");
    }
    if (!this.contentFieldTarget.classList.contains("hidden")) {
      this.contentFieldTarget.classList.add("hidden");
    }
  }

  showContentField(event) {
    if (this.contentFieldTarget.classList.contains("hidden")) {
      this.contentFieldTarget.classList.remove("hidden");
    }
    if (!this.urlFieldTarget.classList.contains("hidden")) {
      this.urlFieldTarget.classList.add("hidden");
    }
  }
}