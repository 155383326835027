import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "div", "textField" ];

  handleKey(event) {
    if (event.keyCode === 83
      && event.target.nodeName !== "INPUT"
      && event.target.nodeName !== "TEXTAREA"
      && !event.ctrlKey
      && !event.shiftKey
      && !event.altKey
      && !event.metaKey
      && !document.activeElement.closest("[contenteditable]")) {
      // 's' pressed outside of an input.
      event.preventDefault();
      this.show();
    } else if (event.keyCode === 27) {
      // Esc pressed.
      this.hide();
    }
  }
  
  handleClick(event) {
    if (!this.divTarget.contains(event.target)) {
      this.hide();
    }
  }

  show() {
    this.textFieldTarget.value = "";
    this.divTarget.style.visibility = "visible";
    this.textFieldTarget.focus();
  }

  hide() {
    this.divTarget.style.visibility = "hidden";
  }
}