import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "address", "phone" ];

  toggleAddressVisibility(event) {
    for (const addressTarget of this.addressTargets) {
      if (addressTarget.classList.contains("hidden")) {
        addressTarget.classList.remove("hidden");
      } else {
        addressTarget.classList.add("hidden");
      }
    }
  }

  togglePhoneVisibility(event) {
    for (const phoneTarget of this.phoneTargets) {
      if (phoneTarget.classList.contains("hidden")) {
        phoneTarget.classList.remove("hidden");
      } else {
        phoneTarget.classList.add("hidden");
      }
    }
  }
}