import ChartController from "./chart_controller";

export default class extends ChartController {

  connect() {
    super.connect();
    this.alarmFactor = this.data.get("alarmFactor");
    this.renderChart();
  }

  renderChart() {
    new App.$chartjs(this.canvasTarget, {
      type: "doughnut",
      data: {
        labels: this.labels,
        datasets: [
          {
            label: "# of Alarms",
            data: this.counts,
            backgroundColor: App.arrayOfRGBColors(this.labels.length, 0.75),
            borderColor: App.arrayOfRGBColors(this.labels.length),
            borderWidth: 1,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        elements: {
          center: {
            text: this.alarmFactor
          },
        },
      },
    });
  }
}