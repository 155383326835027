import CrudPaginationController from "./crud_pagination_controller";

export default class extends CrudPaginationController {
  static targets = [ "eventList", "alarmRow" ];

  connect() {
    this.siteNum = this.data.get("siteNum");
    this.loadList();
  }

  loadList(params) {
    fetch(`/sites/${this.siteNum}/alarms/list?${this.paramsString()}&${params}`)
      .then(response => this.handleFetchErrors(response, this.listTarget))
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
      })
      .finally(() => {
        this.hideLoading();
      });
  }

  loadEventList(event) {
    let alarmNum = event.currentTarget.dataset.alarmNum;
    this.highlightRow(this.alarmRowTargets.indexOf(event.currentTarget));
    fetch(`/sites/${this.siteNum}/alarms/${alarmNum}/list`)
      .then(response => this.handleFetchErrors(response, this.eventListTarget))
      .then(response => response.text())
      .then(html => {
        this.eventListTarget.innerHTML = html;
      })
      .finally(() => {
        this.hideLoading();
      });
  }

  highlightRow(index) {
    this.removeAllRowHighlights();
    let rowTarget = this.alarmRowTargets[index];
    if (!rowTarget.classList.contains("bg-indigo-100")) {
      rowTarget.classList.add("bg-indigo-100");
    }
  }

  removeAllRowHighlights() {
    for (const rowTarget of this.alarmRowTargets) {
      if (rowTarget.classList.contains("bg-indigo-100")) {
        rowTarget.classList.remove("bg-indigo-100");
      }
    }
  }

  clearEventList() {
    this.eventListTarget.innerHTML = "<p>Select an alarm to view its event list.</p>";
  }

  pageUp(event) {
    super.pageUp(event);
    this.clearEventList();
    this.removeAllRowHighlights();
  }

  pageDown(event) {
    super.pageDown(event);
    this.clearEventList();
    this.removeAllRowHighlights();
  }
}
