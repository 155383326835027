// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("@rails/actiontext");
require("channels");

require("trix");
require("trix/dist/trix.css");

// Stimulus
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
const application = Application.start();
const context = require.context("../controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

window.App = {};

import Rails from "@rails/ujs";
window.App.$rails = Rails;

import Chart from "chart.js";
window.App.$chartjs = Chart;

import Quill from "quill";
window.App.$quill = Quill;

import Iconify from "@iconify/iconify";
window.App.$iconify = Iconify;

require("balloon-css/balloon.min.css");

import flatpickr from "flatpickr";
require ("flatpickr/dist/flatpickr.css");

window.App.applyFlatpickr = () => {
  flatpickr("[data-behavior='flatpickr']", {
    altInput: true,
    altFormat: "F j, Y H:i",
    dateFormat: "Y-m-d H:i",
    enableTime: true,
  });
  flatpickr("[data-behavior='flatpickr-date-only']", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
  });
  flatpickr("[data-behavior='flatpickr-time-only']", {
    enableTime: true,
    noCalendar: true,
    altInput: true,
    dateFormat: "H:i",
  });
};

document.addEventListener("turbolinks:load", function() {
  Iconify.scanDOM();
  App.applyFlatpickr();
});

import "../stylesheets/application.css";

window.App.arrayOfRGBColors = (length, opacity = 1.0) => {
  const possibleColors = [
    `rgba(75,78,109, ${opacity})`, // Independence (dark purplish-blue)
    `rgba(91,110,128, ${opacity})`, // Dark Electric Blue
    `rgba(99,126,137, ${opacity})`, // Slate Gray
    `rgba(214,255,246, ${opacity})`, // Light Cyan
    `rgba(211,188,204, ${opacity})`, // Thistle (light purple)
    `rgba(239,148,108, ${opacity})`, // Dark Salmon
    `rgba(196,167,125, ${opacity})`, // Light French Beige
    `rgba(239,71,111, ${opacity})`, // Paradise Pink
  ];
  let array = [];
  for (let i = 0; i < length; i++) {
    // Prevent adjacent identical colors when using this method to set colors for a chart.js pie or doughnut chart,
    // which would occur with the first and last colors otherwise.
    let x = i;
    if ((i === length - 1) && (length % possibleColors.length === 1)) {
      x = i + 1;
    }

    array.push(possibleColors[x % possibleColors.length]);
  }
  return array;
};

// Chart.js plugin to allow for displaying text in center of doughnut.
// Taken from (and slightly modified): https://stackoverflow.com/a/43026361/1196465
Chart.pluginService.register({
  beforeDraw: function (chart) {
    if (chart.config.options.elements.center) {
      // Get ctx from string
      var ctx = chart.chart.ctx;

      // Get options from the center object in options
      var centerConfig = chart.config.options.elements.center;
      var fontStyle = centerConfig.fontStyle || "Lato";
      var txt = centerConfig.text;
      var color = centerConfig.color || "#000";
      var maxFontSize = centerConfig.maxFontSize || 75;
      var sidePadding = centerConfig.sidePadding || 20;
      var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
      // Start with a base font of 30px
      ctx.font = "30px " + fontStyle;

      // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      var stringWidth = ctx.measureText(txt).width;
      var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      var widthRatio = elementWidth / stringWidth;
      var newFontSize = Math.floor(30 * widthRatio);
      var elementHeight = chart.innerRadius * 2;

      // Pick a new font size so it will not be larger than the height of label.
      var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
      var minFontSize = centerConfig.minFontSize;
      var lineHeight = centerConfig.lineHeight || 25;
      var wrapText = false;

      if (minFontSize === undefined) {
        minFontSize = 20;
      }

      if (minFontSize && fontSizeToUse < minFontSize) {
        fontSizeToUse = minFontSize;
        wrapText = true;
      }

      // Set font settings to draw it correctly.
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      ctx.font = fontSizeToUse + "px " + fontStyle;
      ctx.fillStyle = color;

      if (!wrapText) {
        ctx.fillText(txt, centerX, centerY);
        return;
      }

      var words = `${txt}`.split(" ");
      var line = "";
      var lines = [];

      // Break words up into multiple lines if necessary
      for (var n = 0; n < words.length; n++) {
        var testLine = line + words[n] + " ";
        var metrics = ctx.measureText(testLine);
        var testWidth = metrics.width;
        if (testWidth > elementWidth && n > 0) {
          lines.push(line);
          line = words[n] + " ";
        } else {
          line = testLine;
        }
      }

      // Move the center up depending on line height and number of lines
      centerY -= (lines.length / 2) * lineHeight;

      for (var m = 0; m < lines.length; m++) {
        ctx.fillText(lines[n], centerX, centerY);
        centerY += lineHeight;
      }
      //Draw text in center
      ctx.fillText(line, centerX, centerY);
    }
  },
});