import BaseController from "./base_controller";

export default class extends BaseController {
  static targets = [ "siteGroupForm", "alarmFactor", "alarmFactorTrend", "alarmCount", "topAlarmActivity",
                     "excessiveOperatorActions" ];

  connect() {
    this.loadAlarmFactor();
    this.loadAlarmFactorTrend();
    this.loadAlarmCount();
    this.loadTopAlarmActivity();
    this.loadExcessiveOperatorActions();
  }

  changeSiteGroup(event) {
    this.siteGroupFormTarget.submit();
  }

  loadAlarmFactor() {
    fetch(`/dashboard/alarm-factor?${this.paramsString()}`)
      .then(response => this.handleFetchErrors(response, this.alarmFactorTarget))
      .then(response => response.text())
      .then(html => {
        this.alarmFactorTarget.innerHTML = html;
      });
  }

  loadAlarmFactorTrend() {
    fetch(`/dashboard/alarm-factor-trend?${this.paramsString()}`)
      .then(response => this.handleFetchErrors(response, this.alarmFactorTrendTarget))
      .then(response => response.text())
      .then(html => {
        this.alarmFactorTrendTarget.innerHTML = html;
      });
  }

  loadAlarmCount() {
    fetch(`/dashboard/alarm-count?${this.paramsString()}`)
      .then(response => this.handleFetchErrors(response, this.alarmCountTarget))
      .then(response => response.text())
      .then(html => {
        this.alarmCountTarget.innerHTML = html;
      });
  }

  loadTopAlarmActivity() {
    fetch(`/dashboard/top-alarm-activity?${this.paramsString()}`)
      .then(response => this.handleFetchErrors(response, this.topAlarmActivityTarget))
      .then(response => response.text())
      .then(html => {
        this.topAlarmActivityTarget.innerHTML = html;
      });
  }

  loadExcessiveOperatorActions() {
    fetch(`/dashboard/excessive-operator-actions?${this.paramsString()}`)
      .then(response => this.handleFetchErrors(response, this.excessiveOperatorActionsTarget))
      .then(response => response.text())
      .then(html => {
        this.excessiveOperatorActionsTarget.innerHTML = html;
      });
  }

  paramsString() {
    return new URLSearchParams(window.location.search).toString();
  }
}