import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "canvas" ];

  connect() {
    this.labels = this.data.get("labels").split("|");
    this.counts = this.data.get("counts").split("|");
  }

  renderChart() {
    // Inheriting controllers should include this method.
  }
}