import { Controller } from "stimulus";

export default class extends Controller {
  handleFetchErrors(response, target) {
    if (!response.ok) {
      target.innerHTML = "<p>There was a problem loading this data.</p>";
      throw Error(response.statusText);
    } else {
      return response;
    }
  }
}