import CrudController from "./crud_controller";

export default class extends CrudController {
  static targets = [ "config", "configRow" ];

  connect() {
    this.siteNum = this.data.get("siteNum");
    this.devNum = this.data.get("devNum");
    this.loadList();
  }

  loadList(params) {
    fetch(`/sites/${this.siteNum}/devices/${this.devNum}/configs`)
      .then(response => this.handleFetchErrors(response, this.listTarget))
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
      })
      .finally(() => {
        this.hideLoading();
      });
  }

  edit(event) {
    let signalRuleNum = event.currentTarget.dataset.signalRuleNum;
    this.highlightRow(this.configRowTargets.indexOf(event.currentTarget));
    fetch(`/sites/${this.siteNum}/devices/${this.devNum}/configs/${signalRuleNum}/edit`)
      .then(response => this.handleFetchErrors(response, this.configTarget))
      .then(response => response.text())
      .then(html => {
        this.configTarget.innerHTML = html;
      })
      .finally(() => {
        this.hideLoading();
      });
  }

  new(event) {
    this.clearMessage();
    fetch(`/sites/${this.siteNum}/devices/${this.devNum}/configs/new`)
      .then(response => this.handleFetchErrors(response, this.configTarget))
      .then(response => response.text())
      .then(html => {
        this.configTarget.innerHTML = html;
        this.hideNewLink();
      });
  }

  highlightRow(index) {
    this.clearConfig();
    let rowTarget = this.configRowTargets[index];
    if (!rowTarget.classList.contains("bg-indigo-100")) {
      rowTarget.classList.add("bg-indigo-100");
    }
  }

  removeAllRowHighlights() {
    for (const rowTarget of this.configRowTargets) {
      if (rowTarget.classList.contains("bg-indigo-100")) {
        rowTarget.classList.remove("bg-indigo-100");
      }
    }
  }

  handleResponse(event) {
    super.handleResponse(event);
    if (event.detail[0].success) {
      this.clearConfig();
    }
  }

  cancelForm(event) {
    super.cancelForm(event);
    this.clearConfig();
  }

  clearConfig() {
    this.clearMessage();
    this.removeAllRowHighlights();
    this.showNewLink();
    this.configTarget.innerHTML = "";
  }
}
