import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "div" ];

  close(event) {
    event.preventDefault();
    this.hide();
  }

  show() {
    if (this.divTarget.classList.contains("hidden")) {
      this.divTarget.classList.remove("hidden");
    }
  }

  hide() {
    if (!this.divTarget.classList.contains("hidden")) {
      this.divTarget.classList.add("hidden");
    }
  }
}