import CrudController from "./crud_controller";

export default class extends CrudController {
  connect() {
    this.siteNum = this.data.get("siteNum");
    this.loadList();
  }

  loadList() {
    this.clearMessage();
    fetch(`/sites/${this.siteNum}/agencies`)
      .then(response => this.handleFetchErrors(response, this.listTarget))
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
        this.showNewLink();
      });
  }

  new(event) {
    this.clearMessage();
    fetch(`/sites/${this.siteNum}/agencies/new`)
      .then(response => this.handleFetchErrors(response, this.listTarget))
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
        this.hideNewLink();
        this.hideLoading();
      });
  }

  add(event) {
    this.clearMessage();
    let agencyNum = event.currentTarget.dataset.agencyNum;
    let controller = this;
    App.$rails.ajax({
      type: "POST",
      url: `/sites/${controller.siteNum}/agencies`,
      data: new URLSearchParams({ agency_search: `${agencyNum}` }).toString(),
      success(data) {
        controller.loadList();
      },
      error(data) {
        // TODO: Maybe render an error message?
      }
    });
  }
}