import CrudController from "./crud_controller";

export default class extends CrudController {
  connect() {
    this.siteNum = this.data.get("siteNum");
    this.contactNum = this.data.get("contactNum");
    this.loadList();
  }

  loadList() {
    this.clearMessage();
    fetch(`/sites/${this.siteNum}/contacts/${this.contactNum}/phones`)
      .then(response => this.handleFetchErrors(response, this.listTarget))
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
        App.$iconify.scanDOM();
        this.showNewLink();
      });
  }

  swap(event) {
    event.preventDefault();
    event.stopPropagation();
    let seqNum = event.currentTarget.dataset.seqNum;
    let targetSeqNum = event.currentTarget.dataset.targetSeqNum;
    let controller = this;
    App.$rails.ajax({
      type: "PATCH",
      url: `/sites/${this.siteNum}/contacts/${this.contactNum}/phones/${seqNum}/swap/${targetSeqNum}`,
      success(data) {
        controller.loadList();
      },
      error(data) {
        // TODO: Error handling.
        controller.loadList();
      }
    });
  }

  new(event) {
    this.clearMessage();
    fetch(`/sites/${this.siteNum}/contacts/${this.contactNum}/phones/new`)
      .then(response => this.handleFetchErrors(response, this.listTarget))
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
        this.hideNewLink();
      });
  }

  edit(event) {
    this.clearMessage();
    let seqNum = event.currentTarget.dataset.seqNum;
    fetch(`/sites/${this.siteNum}/contacts/${this.contactNum}/phones/${seqNum}/edit`)
      .then(response => this.handleFetchErrors(response, this.listTarget))
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
        this.hideNewLink();
      });
  }
}