import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "lookupDiv", "form", "textField", "results" ];

  showLookup(event) {
    event.preventDefault();
    this.formTarget.reset();
    this.resultsTarget.innerHTML = "";
    if (this.lookupDivTarget.classList.contains("hidden")) {
      this.lookupDivTarget.classList.remove("hidden");
    }
  }

  hideLookup(event) {
    if (!this.lookupDivTarget.classList.contains("hidden")) {
      this.lookupDivTarget.classList.add("hidden");
    }
  }

  cancelForm(event) {
    event.preventDefault();
    this.hideLookup();
  }

  select(event) {
    this.textFieldTarget.value = event.currentTarget.dataset.selectValue;
    this.hideLookup();
  }
}